import React from 'react';

const SectionCard = ({ title, children }) => (
  <div className="bg-white rounded-lg shadow-md p-6 mb-6">
    <h3 className="text-xl font-semibold mb-4 text-gray-800 border-b pb-2">{title}</h3>
    {children}
  </div>
);

const ListItem = ({ title, children }) => (
  <li className="mb-3">
    <span className="font-semibold text-gray-700">{title}: </span>
    {children}
  </li>
);

const NetworkInfo = () => {
  return (
    <div className="bg-gray-100 min-h-screen p-8">
      <div className="max-w-5xl mx-auto">
        <h1 className="text-3xl font-bold mb-8 text-center text-gray-800">Pizza Hotline Network Structure Breakdown</h1>
        
        <SectionCard title="Overview">
          <p className="text-gray-600">
            The network consists of primary and backup systems spread across AWS Canada-Central and Google Cloud US-Central regions, with Cloudflare providing DNS and CDN services.
          </p>
        </SectionCard>

        <SectionCard title="1. Frontend">
          <ul className="list-none space-y-2">
            <ListItem title="Cloudflare DNS/CDN">
              <ul className="list-disc pl-5 mt-2 space-y-1 text-gray-600">
                <li>Domain: pizzahotline.ca</li>
                <li>Services: DNS, CDN</li>
                <li>Registrar: Internic.ca</li>
              </ul>
            </ListItem>
          </ul>
        </SectionCard>

        <SectionCard title="2. Cloud Providers">
          <ul className="list-none space-y-4">
            <ListItem title="AWS Canada-Central (Primary)">
              <ul className="list-disc pl-5 mt-2 space-y-1 text-gray-600">
                <li>Region: ca-central-1</li>
                <li>VPC: Yes</li>
                <li>Firewall: AWS Firewall</li>
              </ul>
            </ListItem>
            <ListItem title="Google Cloud US-Central (Backup)">
              <ul className="list-disc pl-5 mt-2 space-y-1 text-gray-600">
                <li>Region: us-central1</li>
                <li>Firewall: GC Firewall</li>
              </ul>
            </ListItem>
          </ul>
        </SectionCard>

        <SectionCard title="3. Servers">
          <h4 className="font-semibold mb-3 text-gray-700">AWS Servers (Primary)</h4>
          <ul className="list-none space-y-4 mb-6">
            <ListItem title="Hotline OPAL">
              <ul className="list-disc pl-5 mt-2 space-y-1 text-gray-600">
                <li>Type: t3a.xlarge</li>
                <li>Public IP: 15.157.4.186 (Elastic IP)</li>
                <li>DNS: pos.pizzahotline.ca</li>
                <li>Hosts pxplus database</li>
              </ul>
            </ListItem>
            <ListItem title="Hotline Web">
              <ul className="list-disc pl-5 mt-2 space-y-1 text-gray-600">
                <li>Type: t3a.xlarge</li>
                <li>Public IP: 35.183.228.21 (Elastic IP)</li>
                <li>DNS: pizzahotline.ca</li>
              </ul>
            </ListItem>
            <ListItem title="Hotline API/Dev">
              <ul className="list-disc pl-5 mt-2 space-y-1 text-gray-600">
                <li>Type: t2.small</li>
                <li>Public IP: 3.96.215.5 (Non-elastic)</li>
                <li>Purpose: Development and unused API</li>
              </ul>
            </ListItem>
          </ul>

          <h4 className="font-semibold mb-3 text-gray-700">Google Cloud Servers (Backup)</h4>
          <ul className="list-none space-y-4">
            <ListItem title="OPAL Backup">
              <ul className="list-disc pl-5 mt-2 space-y-1 text-gray-600">
                <li>Type: e2-medium</li>
                <li>IP: 35.188.81.184 (Static)</li>
                <li>DNS: pos2.pizzahotline.ca</li>
                <li>Hosts pxplus database</li>
              </ul>
            </ListItem>
            <ListItem title="Web Backup">
              <ul className="list-disc pl-5 mt-2 space-y-1 text-gray-600">
                <li>Type: e2-medium</li>
                <li>Public IP: 34.70.129.17 (Static)</li>
                <li>DNS: pizzahotline.ca</li>
              </ul>
            </ListItem>
          </ul>
        </SectionCard>

        <SectionCard title="4. Security">
          <ul className="list-disc pl-5 space-y-2 text-gray-600">
            <li>AWS Firewall and GC Firewall protecting respective servers</li>
            <li>Open ports: 22, 3000, 3443, 80, 443</li>
          </ul>
        </SectionCard>

        <SectionCard title="5. Monitoring">
          <ul className="list-disc pl-5 space-y-2 text-gray-600">
            <li>UptimeRobot for third-party monitoring of all production servers</li>
          </ul>
        </SectionCard>

        <SectionCard title="6. Data Synchronization">
          <ul className="list-none space-y-4">
            <ListItem title="OPAL Sync: AWS OPAL to GCS OPAL Backup">
              <ul className="list-disc pl-5 mt-2 space-y-1 text-gray-600">
                <li>Critical files: Every 15 minutes</li>
                <li>All files: Daily</li>
                <li>Method: rsync over SSH</li>
              </ul>
            </ListItem>
            <ListItem title="Web Sync: AWS Web to GCS Web Backup">
              <ul className="list-disc pl-5 mt-2 space-y-1 text-gray-600">
                <li>Frequency: Every 15 minutes</li>
                <li>Method: rsync over SSH</li>
              </ul>
            </ListItem>
          </ul>
        </SectionCard>

        <SectionCard title="7. Client Access">
          <ul className="list-disc pl-5 space-y-2 text-gray-600">
            <li>Web Access: Browser to pizzahotline.ca</li>
            <li>OPAL Access: WindX Client to pos.pizzahotline.ca</li>
          </ul>
        </SectionCard>

       
      </div>
    </div>
  );
};

export default NetworkInfo;