import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Server, Cloud, Globe, Monitor, Terminal, Database, Shield, RefreshCw } from 'lucide-react';

const Tooltip = ({ title, details, position }) => {
  return ReactDOM.createPortal(
    <div 
      className="fixed z-50 p-4 text-sm bg-gray-800 rounded-lg shadow-xl w-80 border border-gray-700"
      style={{ 
        left: Math.min(position.x, window.innerWidth - 320),
        top: position.y
      }}
    >
      <h3 className="mb-2 text-lg font-bold text-white">{title}</h3>
      <ul className="space-y-1">
        {Object.entries(details).map(([key, value]) => (
          <li key={key} className="flex justify-between">
            <span className="font-medium text-gray-400">{key}:</span>
            <span className="text-gray-300 text-right">
              {value.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  {index < value.split('\n').length - 1 && <br />}
                </React.Fragment>
              ))}
            </span>
          </li>
        ))}
      </ul>
    </div>,
    document.body
  );
};

const NetworkNode = ({ icon: Icon, title, details, x, y, color, tooltipOnTop = false }) => {
  const [showDetails, setShowDetails] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  let hideTimeout;

  useEffect(() => {
    if (showDetails) {
      const rect = document.getElementById(title).getBoundingClientRect();
      const offset = 10; // Offset for general tooltip positioning

      setTooltipPosition({
        x: rect.left + window.scrollX,
        y: tooltipOnTop 
          ? rect.top + window.scrollY - offset // Position the tooltip above the node
          : rect.bottom + window.scrollY + offset // Default position below the node
      });
    }
  }, [showDetails, title, tooltipOnTop]);

  const handleMouseEnter = () => {
    clearTimeout(hideTimeout); // Clear the timeout when mouse re-enters
    setShowDetails(true);
  };

  const handleMouseLeave = () => {
    hideTimeout = setTimeout(() => {
      setShowDetails(false); // Delay the hiding of the tooltip
    }, 100); // Short delay to prevent the "blip"
  };

  return (
    <>
      <div 
        id={title}
        className="absolute transform -translate-x-1/2 -translate-y-1/2 cursor-pointer group"
        style={{ left: `${x}%`, top: `${y}%` }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="flex flex-col items-center">
          <div className={`p-4 rounded-full ${color} shadow-lg transition-transform duration-300 transform group-hover:scale-110`}>
            <Icon size={32} className="text-white" />
          </div>
          <span className="mt-2 text-sm font-medium text-gray-300 text-center">{title}</span>
        </div>
      </div>
      {showDetails && (
        <div
          onMouseEnter={handleMouseEnter} // Ensure the tooltip stays when hovered
          onMouseLeave={handleMouseLeave} // Hide when the user leaves the tooltip
        >
          <Tooltip title={title} details={details} position={tooltipPosition} />
        </div>
      )}
    </>
  );
};


const Connection = ({ start, end, dashed, label, labelOffset = { x: 0, y: 0 } }) => (
  <svg className="absolute top-0 left-0 w-full h-full pointer-events-none">
    <line
      x1={`${start.x}%`}
      y1={`${start.y}%`}
      x2={`${end.x}%`}
      y2={`${end.y}%`}
      stroke="#4B5563"
      strokeWidth="2"
      strokeDasharray={dashed ? "5,5" : ""}
    />
    {label && (
      <text
        x={`${(start.x + end.x) / 2 + labelOffset.x}%`}
        y={`${(start.y + end.y) / 2 + labelOffset.y}%`}
        dy="-5"
        textAnchor="middle"
        fill="#9CA3AF"
        fontSize="12"
      >
        {label}
      </text>
    )}
  </svg>
);

const NetworkDiagram = () => {
  const nodes = [
    { icon: Globe, title: "Cloudflare DNS/CDN", x: 50, y: 5, color: "bg-blue-500", details: { 
      "Domain": "pizzahotline.ca",
      "Services": "DNS, CDN",
      "Registrar": "Internic.ca",
      "DNS": "pos.pizzahotline.ca\npos2.pizzahotline.ca\npizzahotline.ca\nwww.pizzahotline.ca"
    }},
    { icon: Cloud, title: "AWS Canada-Central", x: 25, y: 25, color: "bg-orange-500", details: { 
      "Region": "ca-central-1",
      "VPC": "Yes",
      "Firewall": "AWS Firewall",
      "Open Ports": "22, 3000, 3443, 80, 443"
    }},
    { icon: Cloud, title: "Google Cloud US-Central", x: 75, y: 25, color: "bg-green-500", details: { 
      "Region": "us-central1",
      "Firewall": "GC Firewall",
      "Open Ports": "22, 3000, 3443, 80, 443"
    }},
    { icon: Server, title: "Hotline OPAL (AWS)", x: 15, y: 45, color: "bg-purple-500", details: { 
      "Type": "t3a.xlarge",
      "Public IP": "15.157.4.186 (Elastic IP)",
      "Local IP": "172.31.19.37",
      "DNS": "pos.pizzahotline.ca",
      "Database": "pxplus (hosted on this server)"
    }},
    { icon: Server, title: "Hotline Web (AWS)", x: 35, y: 65, color: "bg-purple-500", details: { 
      "Type": "t3a.xlarge",
      "Public IP": "35.183.228.21 (Elastic IP)",
      "Local IP": "172.31.10.5",
      "DNS": "pizzahotline.ca"
    }},
    { icon: Server, title: "Hotline API/Dev (AWS)", x: 25, y: 85, color: "bg-purple-500", details: { 
      "Type": "t2.small",
      "Public IP": "3.96.215.5 (Non-elastic)",
      "Local IP": "172.31.17.71",
      "Purpose": "Development and unused API"
    },tooltipOnTop: true},
    { 
      icon: Server, 
      title: "OPAL Backup (GCE)", 
      x: 70, 
      y: 75, 
      color: "bg-indigo-500", 
      details: { 
        "Type": "e2-medium",
        "IP": "35.188.81.184 (Static)",
        "Local IP": "10.128.0.20",
        "Database": "pxplus (hosted on this server)",
        "DNS": "pos2.pizzahotline.ca"
      },
      tooltipOnTop: true // Moved outside of the details object
    },

    { icon: Server, title: "Web Backup (GCE)", x: 85, y: 45, color: "bg-indigo-500", details: { 
      "Type": "e2-medium",
      "Public IP": "34.70.129.17 (Static)",
      "Local IP": "10.128.0.26",
      "DNS": "pizzahotline.ca"
    }},
    { icon: Terminal, title: "UptimeRobot", x: 50, y: 85, color: "bg-red-500", details: { 
      "Service": "Third-party Monitoring",
      "Targets": "All Production Servers"
    },tooltipOnTop: true},
    { icon: Monitor, title: "Client Access", x: 50, y: 30, color: "bg-yellow-500", details: { 
      "Web Access": "Browser to pizzahotline.ca",
      "OPAL Access": "WindX Client to pos.pizzahotline.ca"
    }},
    { icon: Shield, title: "AWS Firewall", x: 10, y: 25, color: "bg-red-500", details: { 
      "Protected Servers": "All AWS servers",
      "Open Ports": "22, 3000, 3443, 80, 443"
    }},
    { icon: Shield, title: "GC Firewall", x: 90, y: 25, color: "bg-red-500", details: { 
      "Protected Servers": "All Google Cloud servers",
      "Open Ports": "22, 3000, 3443, 80, 443"
    }},
    { icon: RefreshCw, title: "OPAL Sync", x: 40, y: 35, color: "bg-yellow-500", details: { 
      "From": "AWS OPAL (15.157.4.186)",
      "To": "GCE OPAL Backup (35.188.81.184)",
      "Critical Files": "Every 15 minutes",
      "All Files": "Daily",
      "Method": "rsync over SSH"
    }},
    { icon: RefreshCw, title: "Web Sync", x: 60, y: 35, color: "bg-yellow-500", details: { 
      "From": "AWS Web (35.183.228.21)",
      "To": "GCE Web Backup (34.70.129.17)",
      "Frequency": "Every 15 minutes",
      "Method": "rsync over SSH"
    }},
  ];

  const connections = [
    { start: { x: 50, y: 5 }, end: { x: 25, y: 25 }, dashed: false, label: "Primary Route" },
    { start: { x: 50, y: 5 }, end: { x: 75, y: 25 }, dashed: true, label: "Backup Route" },
    { start: { x: 15, y: 45 }, end: { x: 35, y: 65 }, dashed: false, label: "Internal Comm" },
    { start: { x: 15, y: 45 }, end: { x: 70, y: 70 }, dashed: true, label: "OPAL Sync", labelOffset: { x: 0, y: -1 } },
    { start: { x: 35, y: 65 }, end: { x: 85, y: 45 }, dashed: true, label: "Web Sync", labelOffset: { x: -5, y: 1 } },
    { start: { x: 50, y: 85 }, end: { x: 25, y: 25 }, dashed: false, label: "Monitor", labelOffset: { x: -5, y: -10 } },
    { start: { x: 50, y: 85 }, end: { x: 75, y: 25 }, dashed: false, label: "Monitor", labelOffset: { x: 5, y: -10 } },
    { start: { x: 50, y: 30 }, end: { x: 50, y: 5 }, dashed: false, label: "User Access" },
    { start: { x: 10, y: 25 }, end: { x: 25, y: 25 }, dashed: false, label: "Protects" },
    { start: { x: 90, y: 25 }, end: { x: 75, y: 25 }, dashed: false, label: "Protects" },
    { start: { x: 75, y: 25 }, end: { x: 70, y: 70 }, dashed: false, label: "Hosts" },
    { start: { x: 75, y: 25 }, end: { x: 85, y: 45 }, dashed: false, label: "Hosts" },
      // Internal Comm line between OPAL Backup (GCS) and Web Backup (GCS)
  { 
    start: { x: 70, y: 75 }, // OPAL Backup (GCS) position
    end: { x: 85, y: 45 },   // Web Backup (GCS) position
    dashed: false,           // Solid line for internal comm
    label: "Internal Comm"
  },
  ];

  return (
    <div className="relative w-full h-screen bg-gray-900 overflow-hidden flex flex-col">
      <div className="w-full p-6 bg-gray-800 bg-opacity-75">
        <h1 className="text-3xl font-bold text-center text-white">Pizza Hotline Comprehensive Network Infrastructure</h1>
      </div>
      <div className="flex-grow relative w-full pt-12">
        {connections.map((conn, index) => (
          <Connection key={index} {...conn} />
        ))}
        {nodes.map((node, index) => (
          <NetworkNode key={index} {...node} />
        ))}
      </div>
    </div>
  );
};

export default NetworkDiagram;