import React, { useState, useEffect } from 'react';
import NetworkDiagram from './NetworkDiagram';
import NetworkInfo from './NetworkInfo';
import Login from './login';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  useEffect(() => {
    const loggedIn = localStorage.getItem('isLoggedIn') === 'true';
    setIsLoggedIn(loggedIn);
  }, []);

  const handleLogin = () => {
    setIsLoggedIn(true);
    localStorage.setItem('isLoggedIn', 'true');
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem('isLoggedIn');
  };

  const toggleInfo = () => {
    setShowInfo(!showInfo);
  };

  return (
    <div className="App">
      {isLoggedIn ? (
        <>
          {showInfo ? <NetworkInfo /> : <NetworkDiagram />}
          <div className="absolute top-4 right-4 space-x-2">
            <button onClick={toggleInfo} className="bg-blue-500 text-white px-4 py-2 rounded">
              {showInfo ? 'Show Diagram' : 'Show Info'}
            </button>
            <button onClick={handleLogout} className="bg-red-500 text-white px-4 py-2 rounded">
              Logout
            </button>
          </div>
        </>
      ) : (
        <Login onLogin={handleLogin} />
      )}
    </div>
  );
}

export default App;